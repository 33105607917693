import React from "react";
import * as serviceWorker from "./serviceWorker";
import { makeStyles } from "@material-ui/core/styles";
import Alert from "@material-ui/lab/Alert";
import Button from "@material-ui/core/Button";

const useStyles = makeStyles((theme) => ({
  root: {
    width: "100%",
    position: "absolute",
    top: 0,
    [theme.breakpoints.down("xs")]: {
      top: 56,
      zIndex: 1000,
    },
  },
}));

const ServiceWorkerWrapper = () => {
  const classes = useStyles();
  const [showReload, setShowReload] = React.useState(false);
  const [waitingWorker, setWaitingWorker] = React.useState(null);

  const onSWUpdate = (registration) => {
    console.warn("Update Available");
    setShowReload(true);
    setWaitingWorker(registration.waiting);
  };

  React.useEffect(() => {
    const checkUpdate = setTimeout(() => {
      serviceWorker.register({ onUpdate: () => onSWUpdate });
    }, 10000);
    return () => clearTimeout(checkUpdate);
  });

  const reloadPage = () => {
    waitingWorker.postMessage({ type: "SKIP_WAITING" });
    setShowReload(false);
    window.location.reload(true);
  };

  return showReload ? (
    <div className={classes.root}>
      <Alert
        severity="warning"
        action={
          <Button color="inherit" size="small" onClick={reloadPage}>
            Reload
          </Button>
        }
      >
        A new version is available !
      </Alert>
    </div>
  ) : null;
};

export default ServiceWorkerWrapper;
