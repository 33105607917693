export var menuItems = [
  {
    name: "ForEx",
    link: "/forEx",
  },
  {
    name: "Service Center",
    link: "/service-center",
  },
  {
    name: "Gallery",
    link: "/gallery",
  },
  {
    name: "Event",
    link: "/events",
  },
  {
    name: "About",
    link: "/about-us",
  },
  {
    name: "Contact",
    link: "/contact-us",
  },
];
