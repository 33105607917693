import React from "react";
// import logo from "./logo.svg";
import "./App.css";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import { createMuiTheme, ThemeProvider } from "@material-ui/core/styles";
import { orange } from "@material-ui/core/colors";
import RouteContainer from "./layouts/RouteContainer/RouteContainer";

const theme = createMuiTheme({
  status: {
    danger: orange[500],
  },
});

function App() {
  return (
    <ThemeProvider theme={theme}>
      <RouteContainer />
    </ThemeProvider>
  );
}

export default App;
