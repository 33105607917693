import React, { Component } from "react";

import "./Backdrop.scss";

class Backdrop extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <div className="backdrop" onClick={this.props.toggleBurgerClickHandler} />
    );
  }
}

export default Backdrop;
