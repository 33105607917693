import React from "react";
import { makeStyles } from "@material-ui/core/styles";
import BottomNavigation from "@material-ui/core/BottomNavigation";
import BottomNavigationAction from "@material-ui/core/BottomNavigationAction";
import HomeIcon from "@material-ui/icons/Home";
import GestureIcon from "@material-ui/icons/Gesture";
import DownloadIcon from "@material-ui/icons/GetApp";
import AnnouncementIcon from "@material-ui/icons/Announcement";
import { useHistory } from "react-router-dom";

const useStyles = makeStyles({
  root: {
    width: "100%",
    position: "fixed",
    bottom: 0,
    backgroundColor: "#cf171f",
  },
});

export default function LabelBottomNavigation() {
  const classes = useStyles();
  const history = useHistory();
  const [value, setValue] = React.useState(history.location.pathname || "/");

  const handleChange = (event, newValue) => {
    history.push(newValue);
    setValue(newValue);
  };

  return (
    <BottomNavigation
      value={value}
      onChange={handleChange}
      className={classes.root}
    >
      <BottomNavigationAction label="Home" value="/" icon={<HomeIcon />} />
      <BottomNavigationAction
        label="Products"
        value="/products"
        icon={<GestureIcon />}
      />
      <BottomNavigationAction
        label="Downloads"
        value="/downloads"
        icon={<DownloadIcon />}
      />
      <BottomNavigationAction
        label="Notice"
        value="/notice"
        icon={<AnnouncementIcon />}
      />
    </BottomNavigation>
  );
}
