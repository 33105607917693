import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import MoreIcon from "@material-ui/icons/MoreVert";
import IconButton from "@material-ui/core/IconButton";
import MenuIcon from "@material-ui/icons/Menu";
import MobileBurger from "./mobileBurger";
import Menu from "@material-ui/core/Menu";
import MenuItem from "@material-ui/core/MenuItem";
import Backdrop from "../../components/Backdrop/Backdrop";

const useStyles = makeStyles((theme) => ({
  root: {
    flexGrow: 1,
  },
  appBar: {
    backgroundColor: "#cf171f",
  },
  menuButton: {
    marginRight: theme.spacing(2),
  },
  title: {
    flexGrow: 1,
  },
}));

export default function ButtonAppBar() {
  const [open, setOpen] = useState(false);
  const classes = useStyles();
  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  let backDrop;

  if (open) {
    backDrop = <Backdrop toggleBurgerClickHandler={() => setOpen(false)} />;
  }

  return (
    <div className={classes.root}>
      <MobileBurger open={open} setOpen={(bool) => setOpen(bool)} />
      {backDrop}
      <AppBar position="fixed" className={classes.appBar}>
        <Toolbar>
          <IconButton
            edge="start"
            className={classes.menuButton}
            color="inherit"
            aria-label="menu"
            onClick={() => setOpen(true)}
          >
            <MenuIcon />
          </IconButton>
          <Typography variant="h6" className={classes.title}>
            <img
              alt="..."
              src={require("../../assets/images/logo-reworked.png")}
              style={{ maxWidth: 122 }}
            />
          </Typography>
          <IconButton
            aria-label="display more actions"
            edge="end"
            color="inherit"
            onClick={handleClick}
          >
            <MoreIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleClose}>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="http://ebanking.prabhucooperative.com.np/clientpanel/"
              >
                E-Banking
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="#/office-hour"
              >
                Office Hour
              </a>
            </MenuItem>
            <MenuItem onClick={handleClose}>
              <a
                style={{ color: "black", textDecoration: "none" }}
                href="#/additional"
              >
                EMI Calculator
              </a>
            </MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
    </div>
  );
}
