import React, { Component } from "react";

import Navbar from "./Navbar/Navbar";
import SideBar from "./SideBar/SideBar";
import Backdrop from "../../components/Backdrop/Backdrop";

class Header extends Component {
  constructor(props) {
    super(props);

    this.state = {
      burgerMenu: false,
    };
  }

  toggleBurger = () => {
    this.setState((prevState) => {
      return { burgerMenu: !prevState.burgerMenu };
    });
  };

  render() {
    let sideBar;
    let backDrop;

    if (this.state.burgerMenu) {
      backDrop = <Backdrop toggleBurgerClickHandler={this.toggleBurger} />;
    }
    return (
      <React.Fragment>
        <Navbar toggleBurgerClickHandler={this.toggleBurger} />
        <SideBar show={this.state.burgerMenu} />
        {backDrop}
      </React.Fragment>
    );
  }
}

export default Header;
