import React, { useState } from "react";
const Calculator = () => {
  const [value, setValue] = useState({
    loan: "",
    rate: "",
    term: "",
    monthlyEMI: "",
  });

  const calculateEMI = () => {
    const { loan, rate, term } = value;
    var interest = rate / 1200;
    var top = Math.pow(1 + interest, term);
    var bottom = top - 1;
    var ratio = top / bottom;
    var EMI = (loan * interest * ratio).toFixed(2);
    setValue((prev) => ({
      ...prev,
      monthlyEMI: isFinite(parseFloat(EMI)) ? parseFloat(EMI) : 0,
    }));
  };

  const handleChange = (e) => {
    e.preventDefault();
    var { name, value } = e.target;
    setValue((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  return (
    <>
      <ul>
        <li className="calc">
          <span>Loan Amount (Rs.):</span>
          <input type="number" name="loan" onChange={handleChange} />
        </li>
        <li className="calc">
          <span>Interest Rates (%):</span>
          <input type="number" name="rate" onChange={handleChange} />
        </li>
        <li className="calc">
          <span>Terms (Months):</span>
          <input type="number" name="term" onChange={handleChange} />
        </li>
        <li className="calc">
          <span />
          <input type="button" value="Calculate" onClick={calculateEMI} />
        </li>
        <li className="calc">
          <span>Monthly EMI (Rs.):</span>
          <input type="number" disabled value={value.monthlyEMI} />
        </li>
      </ul>
    </>
  );
};

export default Calculator;
