import React, { Component } from "react";
import Sticky from "react-stickynode";
import { NavLink, Link } from "react-router-dom";
import Axios from "axios";
import $ from "jquery";

import "./Navbar.scss";
import { menuItems } from "../menuItems";
import { api_hot_notice } from "../../../extra/url";

const monthInWords = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class Navbar extends Component {
  constructor(props) {
    super(props);

    this.state = {
      hotNews: [],
      currDate: "",
    };
  }

  componentDidMount() {
    Axios.get(api_hot_notice).then((res) => {
      this.setState({ hotNews: res.data.data });
    });

    const date = new Date();
    const day = date.getDate();
    const month = date.getMonth();
    const year = date.getFullYear();
    const currDate = day + " " + monthInWords[month] + ", " + year;
    this.setState({ currDate });
  }

  showFbPage = () => {
    let link = document.createElement("a");
    link.href = "https://www.facebook.com/prabhucooperativeservice";
    link.setAttribute("target", "_blank");
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  render() {
    return (
      <header className="navContainer">
        <div className="topHeader">
          <div>
            <i
              className="fa fa-calendar"
              aria-hidden="true"
              style={{ color: "#cf171f", margin: "0 6px" }}
            ></i>
            <span style={{ fontSize: "13px", fontWeight: "bold" }}>
              {this.state.currDate}
            </span>
            <img
              alt="..."
              src={require("../../../assets/images/phone-icon1.png")}
              className="ml-3"
            />
            <span style={{ fontSize: "13px", fontWeight: "bold" }}>
              01-4988728, 4988729
            </span>
          </div>
          <div className="spacing" />
          <div>
            <img
              alt="..."
              src={require("../../../assets/images/mail-icon1.png")}
            />
            <span style={{ fontSize: "13px", fontWeight: "bold" }}>
              info@prabhucooperative.com.np{" "}
            </span>
            |{" "}
            <img
              alt="..."
              src={require("../../../assets/images/facebook-icon1.png")}
              className="fb"
              onClick={this.showFbPage}
            />
          </div>
        </div>
        <div className="logoContainer">
          <Link to="/">
            <img
              src={require("../../../assets/images/logo-reworked.png")}
              alt="LOGO"
            />
          </Link>
          <div className="spacing" />

          {/* <a href="http://ebanking.prabhucooperative.com.np/clientpanel/"> */}
            <a href="https://ebanking.prabhucooperative.com.np/login">
            <button className="ebanking-btn">
              <img
                alt="..."
                src={require("../../../assets/images/e-banking-icon1.png")}
              />
              E-Banking
            </button>
          </a>
        </div>
        <Sticky enabled={true} top={0}>
          <nav>
            <div className="navMenu">
              <div
                className="burger-btn"
                onClick={this.props.toggleBurgerClickHandler}
              >
                <div className="line" />
                <div className="line" />
                <div className="line" />
              </div>
              <ul>
                {menuItems.map((item) => (
                  <li key={item.name}>
                    <NavLink exact to={item.link} activeClassName="active">
                      {item.name}
                    </NavLink>
                  </li>
                ))}
              </ul>
            </div>
          </nav>
          <div className="news-slider">
            <span className="leftLabel">
              <Link to={"/notice"}>
                <img
                  alt="..."
                  src={require("../../../assets/images/fire-icon1.png")}
                />
                <label>News/Notice:</label>
              </Link>
            </span>
            <marquee>
              {this.state.hotNews.map((hotNews, i) => {
                let news =
                  "(" + (i + 1) + ")" + ". " + hotNews.notice_title + ".*** ";
                return news;
              })}
            </marquee>
          </div>
        </Sticky>
      </header>
    );
  }
}

export default Navbar;
