import React, { lazy, Suspense, useEffect, useState } from "react";
import { HashRouter as Router, Switch, Route } from "react-router-dom";
import Loaders from "../../components/Loader/Loader";
import { AnimatePresence, motion } from "framer-motion";
import ScrollToTop from "../../utils/ScrollToTop";

import Header from "../Header/Header";
import MobileHeader from "../../layouts/mobileHeader/mobileHeader";
import Footer from "../Footer/Footer";
import MobileFooter from "../../layouts/mobileTabs/mobileTabs";

const Home = lazy(() => import("../../components/Home/Home"));
const OfficeHour = lazy(() => import("../../components/Holiday/holiday"));
const Additional = lazy(() => import("../additional/additional"));
const Service = lazy(() => import("../../components/Service/Service"));
const Notice = lazy(() => import("../../components/Notice/Notice"));
const Gallery = lazy(() => import("../../components/Gallery/Gallery"));
const Events = lazy(() => import("../../components/Events/Events"));
const About = lazy(() => import("../../components/About/About"));
const Contact = lazy(() => import("../../components/Contact/Contact"));
const Branch = lazy(() => import("../../components/Branch/Branch"));
const Career = lazy(() => import("../../components/Career/Career"));
const ForEx = lazy(() => import("../../components/ForEx/ForEx"));
const NoticeDetail = lazy(() => import("../../components/Notice/NoticeDetail"));
const Downloads = lazy(() => import("../../components/Downloads/Downloads"));

const main = {
  height: "calc(100vh - 112px)",
  marginTop: "56px",
};

const pageVariants = {
  initial: {
    opacity: 0,
  },
  in: {
    opacity: 1,
  },
  out: {
    opacity: 0,
  },
};

const pageTransition = {
  type: "tween",
  ease: "linear",
  duration: 0.3,
};

function routes() {
  return (
    <motion.div
      initial="initial"
      animate="in"
      exit="out"
      variants={pageVariants}
      transition={pageTransition}
    >
      <Suspense fallback={<Loaders />}>
        <Switch>
          <Route path="/" exact component={Home} />
          <Route path="/products" exact component={Service} />
          <Route path="/notice" exact component={Notice} />
          <Route path="/notice/:id" exact component={NoticeDetail} />
          <Route path="/gallery" exact component={Gallery} />
          <Route path="/events" exact component={Events} />
          <Route path="/about-us" exact component={About} />
          <Route path="/contact-us" exact component={Contact} />
          <Route path="/service-center" exact component={Branch} />
          <Route path="/career" exact component={Career} />
          <Route path="/forEx" exact component={ForEx} />
          <Route path="/downloads" exact component={Downloads} />
          <Route path="/additional" exact component={Additional} />
          <Route path="/office-hour" exact component={OfficeHour} />
        </Switch>
      </Suspense>
    </motion.div>
  );
}

const RouteContainer = () => {
  const [winSize, setWinSize] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWinSize(window.innerWidth);
    }
    window.addEventListener("resize", handleResize);
    return function cleanupListener() {
      window.removeEventListener("resize", handleResize);
    };
  });
  return (
    <React.Fragment>
      <Router>
        <ScrollToTop>
          <AnimatePresence>
            {winSize > 600 ? (
              <>
                <Header />
                <main className="main">{routes()}</main>
                <Footer />
              </>
            ) : (
              <>
                <MobileHeader />
                <main style={main}>{routes()}</main>
                <MobileFooter />
              </>
            )}
          </AnimatePresence>
        </ScrollToTop>
      </Router>
    </React.Fragment>
  );
};

export default RouteContainer;
