import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import Axios from "axios";
import { api_homepage } from "../extra/url";

const ForEx = () => {
  const [forex, setForex] = useState(
    JSON.parse(localStorage.getItem("forex")) || []
  );
  const [updatedDate, setUpdatedDate] = useState(
    JSON.parse(localStorage.getItem("updatedDate")) || null
  );
  useEffect(() => {
    async function forex() {
      await Axios.get(api_homepage)
        .then((res) => {
          localStorage.setItem("forex", JSON.stringify(res.data.forex_data));
          setForex(res.data.forex_data);
          localStorage.setItem(
            "updatedDate",
            JSON.stringify(res.data.forex_updated)
          );
          setUpdatedDate(res.data.forex_updated);
        })
        .catch((err) => {
          setForex(JSON.parse(localStorage.getItem("forex")));
          setUpdatedDate(JSON.parse(localStorage.getItem("updatedDate")));
        });
    }
    forex();
  }, []);

  return (
    <>
      <table>
        <thead>
          <tr>
            <th>Currency</th>
            <th>Buying</th>
            <th>Selling</th>
          </tr>
        </thead>
        <tbody>
          {forex.map((forex, i) => (
            <tr key={i}>
              <td>{forex.currency}</td>
              <td className="text-right">{forex.buying_b}</td>
              <td className="text-right">{forex.selling}</td>
            </tr>
          ))}
          <tr>
            <td colSpan="2" className="text-center">
              [{updatedDate}]
            </td>
            <td colSpan="2">
              <Link to={"/forEx"}>View all</Link>
            </td>
          </tr>
        </tbody>
      </table>
    </>
  );
};

export default ForEx;
