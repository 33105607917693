import React, { Component } from "react";
import Loader from "react-loader-spinner";

import "./Loader.scss";

class Loaders extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  render() {
    return (
      <Loader
        type="Puff"
        color="#cf171f"
        height={100}
        width={100}
        timeout={10000} //3 secs
      />
    );
  }
}

export default Loaders;
