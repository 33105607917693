import React, { Component } from "react";

import { api_viewcount } from "../../extra/url";
import "./Home.scss";
import Axios from "axios";

class ViewCount extends Component {
  constructor(props) {
    super(props);

    this.state = {
      viewCount: JSON.parse(localStorage.getItem("viewCount")) || [],
    };
  }

  componentDidMount() {
    Axios.get(api_viewcount)
      .then((res) => {
        var number =
          "00000".substring(
            0,
            "00000".length - res.data.data[0].view_count.toString().length
          ) + res.data.data[0].view_count.toString();
        var count = number
          .toString(10)
          .replace(/\D/g, "0")
          .split("")
          .map(Number);
        localStorage.setItem("viewCount", JSON.stringify(count));
        this.setState({ viewCount: count });
      })
      .catch((err) => {
        this.setState({
          viewCount: JSON.parse(localStorage.getItem("viewCount")),
        });
      });
  }

  render() {
    return (
      <div className="view-count">
        <i>Views : </i>
        <div className="mt-3">
          {this.state.viewCount.map((number, i) => (
            <span key={i}>{number}</span>
          ))}
        </div>
      </div>
    );
  }
}

export default ViewCount;
