import "./extra/pollyfills";
import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import "bootstrap/dist/css/bootstrap.css";
import "font-awesome/css/font-awesome.min.css";
import * as serviceWorker from "./serviceWorker";
import "react-loader-spinner/dist/loader/css/react-spinner-loader.css";
import ServiceWorkerWrapper from "./ServiceWorkerWrapper";

ReactDOM.render(
  <>
    <ServiceWorkerWrapper />
    <App />
  </>,
  document.getElementById("root")
);

serviceWorker.register();
