export var menuItems = [
  {
    name: "Home",
    link: "/",
  },
  {
    name: "Notices",
    link: "/notice",
  },
  {
    name: "Products",
    link: "/products",
  },
  {
    name: "Gallery",
    link: "/gallery",
  },
  {
    name: "Event",
    link: "/events",
  },
  {
    name: "Downloads",
    link: "/downloads",
  },
  {
    name: "About",
    link: "/about-us",
  },
  {
    name: "Contact",
    link: "/contact-us",
  },
];
