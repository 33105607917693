import React from "react";
import { NavLink } from "react-router-dom";
import ViewCount from "../../components/Home/ViewCount";

import "../Header/SideBar/SideBar.scss";
import { menuItems } from "./menuItems";

const MobileBurger = ({ open, setOpen }) => {
  let sidebarClass = "sidebar";
  if (open) {
    sidebarClass = "sidebar open";
  }
  return (
    <div className={sidebarClass}>
      <ul>
        {menuItems.map((item) => (
          <li key={item.name}>
            <NavLink
              exact
              to={item.link}
              activeClassName="active"
              onClick={() => setOpen(false)}
            >
              {item.name}
            </NavLink>
          </li>
        ))}
        <li>
          <a href="http://ebanking.prabhucooperative.com.np/clientpanel/">
            E-Banking
          </a>
        </li>
      </ul>
      <div style={{ marginTop: "5rem" }}>
        <ViewCount />
      </div>
    </div>
  );
};

export default MobileBurger;
