import React, { Component } from "react";
import $ from "jquery";
import { Container } from "@material-ui/core";
import Axios from "axios";
import { NavLink } from "react-router-dom";
import ViewCount from "../../components/Home/ViewCount";
import ForEx from "../forex";

import "./Footer.scss";
import { api_holidays } from "../../extra/url";
import Calculator from "../calculator";

var menuItems = [
  {
    name: "Products",
    link: "/products",
  },
  {
    name: "Office Hour",
    link: "/office-hour",
  },
  {
    name: "Service Center",
    link: "/service-center",
  },
  {
    name: "About Us",
    link: "/about-us",
  },
  {
    name: "Career",
    link: "/career",
  },
];

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

class Footer extends Component {
  constructor(props) {
    super(props);

    this.state = {
      downloads: [],
      upcomingHoliday:
        JSON.parse(localStorage.getItem("upcomingHoliday")) || [],
    };
  }

  componentDidMount() {
    Axios.get(api_holidays)
      .then((res) => {
        localStorage.setItem("upcomingHoliday", JSON.stringify(res.data.data));
        this.setState({
          upcomingHoliday: res.data.data,
        });
      })
      .catch((err) => {
        this.setState({
          upcomingHoliday: JSON.parse(localStorage.getItem("upcomingHoliday")),
        });
      });
  }

  formatDate = (date) => {
    var d = new Date(date),
      month = "" + d.getMonth(),
      day = "" + d.getDate();

    return [months[month], day].join(" ");
  };

  handleChange = (e) => {
    e.preventDefault();
    var { name, value } = e.target;
    this.setState({
      [name]: value,
    });
  };

  render() {
    return (
      <footer>
        <Container className="footer">
          <div className="row">
            <div className="col-lg-3 col-md-6">
              <label>Contact</label>
              <ul>
                <li>
                  <img
                    alt="..."
                    src={require("../../assets/images/globe-location-icon1.png")}
                  />
                  Kathmandu-26, <p>Gongabu, Nepal</p>
                </li>
                <li>
                  <img
                    alt="..."
                    src={require("../../assets/images/phone-icon1.png")}
                  />
                  Phone:<p>01-4988728, 4988729</p>
                </li>
                <li>
                  <img
                    alt="..."
                    src={require("../../assets/images/mail-icon1.png")}
                  />
                  Email:<p>info@prabhucooperative.com.np</p>
                </li>
              </ul>
            </div>

            <div className="col-lg-2 col-md-6">
              <label>Useful Links</label>
              <ul className="footer-links">
                {menuItems.map((item) => (
                  <NavLink
                    exact
                    to={item.link}
                    activeClassName="active"
                    key={item.name}
                  >
                    <li className="clickable">
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      {item.name}
                    </li>
                  </NavLink>
                ))}
              </ul>
            </div>

            <div className="col-lg-3 col-md-6">
              <label style={{ paddingBottom: "12px" }}>ForEx</label>
              <ForEx />
              <span>(Note: Only for information)</span>
            </div>
            <div className="col-lg-4 col-md-6">
              <label>EMI Calculator</label>
              <Calculator />
            </div>
            <div className="col-lg-5 col-md-6 mt-4">
              <ViewCount />
            </div>
            {/* {this.state.upcomingHoliday.length > 0 ? (
              <div className="col-lg-6 col-md-6 mt-4">
                <label>Working Hours in Holidays</label>
                {console.log(this.state.upcomingHoliday)}
                {this.state.upcomingHoliday.map((res, i) => (
                  <React.Fragment key={i}>
                    <div className="h5">
                      <i
                        className="fa fa-angle-double-right"
                        aria-hidden="true"
                      ></i>
                      {res.holiday} (
                      <span>{this.formatDate(res.starting_date)}</span>
                      {res.ending_date ? (
                        <span> - {this.formatDate(res.ending_date)}</span>
                      ) : null}
                      )
                    </div>

                    {res.holiday_info.map((info, i) => (
                      <React.Fragment key={i}>
                        <div className="ml-4">
                          <span>{info.service_center}</span> : ({" "}
                          {info.opening_hour} {info.closing_hour} )
                        </div>
                      </React.Fragment>
                    ))}
                  </React.Fragment>
                ))}
              </div>
            ) : null} */}
          </div>
        </Container>
        <div className="copyright">
          <span>
            Copyrights @ 2020 Prabhu Co-operative. All rights reserved
          </span>
        </div>
      </footer>
    );
  }
}

export default Footer;
