// export var base_url = "http://localhost:8000/";
export var base_url = "https://admin.prabhucooperative.com.np/";
// export var base_url = "http://poudelpratik.com.np/backend/public/";

export var image_base_url = base_url + "storage/";

export var map_key = "AIzaSyAXaFUvW-NQS3zZM1342zeQhgnRPKWUaiU";

export var api_homepage = base_url + "api/homepage";
export var api_slider = base_url + "api/bannerImage";
export var api_notice = base_url + "api/notice";
export var api_gallery = base_url + "api/galleryImage";
export var api_service = base_url + "api/service";
export var api_hot_notice = base_url + "api/noticeHot";
export var api_popup_service = base_url + "api/noticePopup";
export var api_download = base_url + "api/form";
export var api_event = base_url + "api/scheme";
export var api_upcoming_event = base_url + "api/eventUpcoming";
export var api_past_event = base_url + "api/eventPast";
export var api_contact = base_url + "api/contact";
export var api_career = base_url + "api/vacancy";
export var api_branch = base_url + "api/branch";
export var api_member = base_url + "api/member";
export var api_forex = base_url + "api/foreign";
export var api_counter = base_url + "api/count/1";
export var api_viewcount = base_url + "api/viewcount";
export var api_workingHours = base_url + "api/workingHours";
export var api_workinHour_single = base_url + "api/workingHour";
export var api_holidays = base_url + "api/holidays";
