import React, { Component } from "react";
import { NavLink } from "react-router-dom";

import "./SideBar.scss";
import { menuItems } from "../menuItems";

class SideBar extends Component {
  constructor(props) {
    super(props);

    this.state = {};
  }

  isActive(link) {
    console.log(this.context);
    console.log(link);
  }

  render() {
    let sidebarClass = "sidebar";
    if (this.props.show) {
      sidebarClass = "sidebar open";
    }
    return (
      <div className={sidebarClass}>
        <ul>
          {menuItems.map((item) => (
            <li key={item.name}>
              <NavLink exact to={item.link} activeClassName="active">
                {item.name}
              </NavLink>
            </li>
          ))}
          <li>
            <a href="http://ebanking.prabhucooperative.com.np/clientpanel/">
              E-Banking
            </a>
          </li>
        </ul>
      </div>
    );
  }
}

export default SideBar;
